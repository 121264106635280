import { useDispatch } from "react-redux";
import { GlobalSharedReduxFn } from "../Context";
export function useLoaderService() {
  const dispatch = useDispatch();
  function startLoader() {
    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
  }
  function stopLoader() {
    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
  }
  return { startLoader, stopLoader };
}
