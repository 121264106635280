import React from "react";

const ClientCard = ({ img, title, text }) => {
  return (
    <>
      <div
        className="card mx-3 my-2 m-0 p-0 bg-white overflow-hidden textElipse"
        style={{
          width: "300px",
          height: "450px",
        }}
      >
        <div
          className="my-0 py-0  d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "250px" }}
        >
          <img
            className=""
            src={img}
            style={{ height: "100%", width: "100%" }} // Added Image Height Witdth to 100%
          />
        </div>
        <div
          className="card-body h-3 pb-2"
          style={{
            width: "300px",
            height: "200px",
          }}
        >
          {/* <p style={{textAlign:"justify"}}> */}
          <p>
            <label className="fw-bold text-uppercase">{title}</label>
            {/* <label className="text-justify">{text}</label> */}
            <div
              style={{
                height: "100%",
                width: "100%",
                textOverflow: "ellipsis",
              }}
            >
              {text}
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default ClientCard;
