import UserManager from "../../Components/UserManager";
import ServiceManager from "../../Components/ServiceManager";
import Configuration from "./Configuration";
import LeftSidebarComp from "../../Components/LeftSidebarComp";
import { useSelector } from "react-redux";
import { WebConfig } from "../../WebConfig";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GlobalSharedReduxFn } from "../../Context";
import ClientConfiguration from "./ClientConfiguration";

const Dashboard = () => {
  const showSideBar = useSelector(
    (state) => state.GlobalSharedRedux.expandSidebar
  );

  const userToken = useSelector(
    (state) => state[WebConfig.contextKeys.currentUserRedux].authToken
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GlobalSharedReduxFn.sidebarToggle(true));
  }, [dispatch]);

  // if (!showSideBar) {
  //     return null;
  // }

  return (
    <>
      <div className="row container-fluid">
        <div className="col-lg-2">
          <LeftSidebarComp></LeftSidebarComp>
        </div>
        <div
          className={`${
            !showSideBar ? "col-lg-12" : "col-lg-10"
          } bg-white border-top`}
        >
          {/* <UserManager /> */}
          <Routes>
            <Route path="/" element={<Navigate to="/admin/user-manager" />} />
            <Route path="user-manager" element={<UserManager />} />
            <Route path="service-manager" element={<ServiceManager />} />
            <Route path="configuration" element={<Configuration />} />
            <Route
              path="client-configuration"
              element={<ClientConfiguration />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
