import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";
import Card from "react-bootstrap/Card";
import { ErrorControl, ReactModal, TextControl } from "../../Components";
import { FnHttpService, FnNotificationUI } from "../../Utilities";
import url from "../../env";
import { useLoaderService } from "../../Utilities/LoaderService";
import showPopUp from "../../Utilities/ShowPopUp";

const Configuration = () => {
  const [isModalOpen, setModalState] = useState(false);
  const { startLoader, stopLoader } = useLoaderService();
  // const url = 'https://localhost:44397/';
  // let url = "https://evisa-dev-api.cloud7info.com/";
  const [imageUrl, setImageUrl] = useState("");
  const [configuration, setConfiguration] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  // const url = "http://api.bedco-consult.com/";

  useEffect(() => {
    getConfigurationList();
  }, []);

  const getConfigurationList = async () => {
    try {
      startLoader();
      const response = await axios.get(url + "api/Config");
      const data = response.data?.data;
      console.log("config", data);
      setConfiguration(data);
    } catch (error) {
      FnNotificationUI.notifyError([], "Network Error");
      console.error("Error:", error.message);
    } finally {
      stopLoader();
    }
  };

  const SubmitConfiguration = async (data) => {
    try {
      if (!imageUrl && data.imageFile) {
        data.imageFile = data.imageFile[0];
      }

      const confirm = await showPopUp({
        title: `Are you sure to ${data.id ? "Update" : "Create"}?`,
        text: "You can Change this Later !",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#1e7e34",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${data.id ? "Update" : "Create"}!`,
      });
      if (confirm) {
        startLoader();
        const formData = new FormData();
        FnHttpService.toFormData(formData, data);
        if (data.id) {
          const response = await axios.put(
            url + "api/Config/" + data.id,
            formData
          );
          console.log("update", response);
        } else {
          const response = await axios.post(url + "api/Config", formData);
          console.log("create", response);
        }
        showPopUp({
          title: "Form submitted successfully!",
          icon: "success",
          willClose: () => {
            FnNotificationUI.notifySuccess(
              [],
              `${data.id ? "Updated" : "Created"} Successfully `
            );
            getConfigurationList();
            setImageUrl("");
            reset({});
            setModalState(false);
          },
        });
      }
    } catch (error) {
      console.log("Config Error", error);
      FnNotificationUI.notifyError(
        [],
        `Failed to ${data.id ? "Update" : "Create"}`
      );
    } finally {
      stopLoader();
    }
  };

  const deleteConfiguration = async (id) => {
    try {
      const confirm = await showPopUp({
        title: "Are you sure to delete?",
        text: "You won't be able to change this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#1e7e34",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      });
      if (confirm) {
        startLoader();
        const response = await axios.delete(url + "api/Config/" + id);
        if (response.data.isSuccess) {
          console.log("Delete", response);
          showPopUp({
            title: "Form submitted successfully!",
            icon: "success",
            willClose: () => {
              FnNotificationUI.notifySuccess([], "Deleted Successfully");
              getConfigurationList();
              setModalState(false);
              setImageUrl("");
            },
          });
        }
      }
    } catch (error) {
      console.log("Error", error);
      FnNotificationUI.notifyError([], "Failed to Delete");
    } finally {
      stopLoader();
    }
  };

  const openFormEditor = (item) => {
    console.log(item);
    if (item?.imagePath) {
      setImageUrl(url + item.imagePath);
    }
    reset(item || {});
    setModalState(true);
  };

  const updateConfiguration = (configData) => {
    openFormEditor(configData);
  };

  const onModalClose = () => {
    reset({});
    setImageUrl("");
    setModalState(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setImageUrl(fileURL);
      setValue("imageFile", file);
    }
  };

  return (
    <>
      <div className="d-flex">
        {isModalOpen && (
          <ReactModal
            size={"md"}
            onModalClose={() => {
              onModalClose();
            }}
          >
            <form onSubmit={handleSubmit(SubmitConfiguration)}>
              <Card>
                <Card.Header>
                  <h3>Configuration</h3>
                </Card.Header>
                <Card.Body>
                  <div className="px-4 border-rounded border-dark">
                    <TextControl
                      name="itemKey"
                      formReg={register("itemKey", {
                        required: "Configuration Key Required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is only 100 char.",
                        },
                      })}
                      lblText="Configuration Key"
                      errorObj={errors}
                    ></TextControl>

                    <TextControl
                      name="itemKeyValue"
                      formReg={register("itemKeyValue", {
                        required: "Key Value Required",
                        maxLength: {
                          value: 200,
                          message: "Max Length is only 200 char.",
                        },
                      })}
                      lblText="Key Value"
                      errorObj={errors}
                    ></TextControl>

                    <TextControl
                      name="itemValue"
                      formReg={register("itemValue", {
                        required: "Configuration Value Required",
                        maxLength: {
                          value: 2000,
                          message: "Max Length is only 2000 char.",
                        },
                      })}
                      lblText="Configuration Value"
                      errorObj={errors}
                    ></TextControl>

                    <ErrorControl name={""} errorObj={errors}></ErrorControl>
                  </div>
                  <div className="row px-4 border-rounded border-dark">
                    {imageUrl ? (
                      <div className="row mx-auto">
                        <div className="col-4">
                          <img
                            src={imageUrl}
                            style={{ height: "150px", width: "200px" }}
                            alt="uploaded-data"
                          />
                        </div>
                        <div className="col-7 d-flex align-items-center">
                          <div className="input-group mb-3 justify-content-end">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupFile01"
                            >
                              Change Image
                            </label>
                            <input
                              className="form-control-file py-2 d-none"
                              id="inputGroupFile01"
                              type="file"
                              {...register("imageFile", {
                                validate: {
                                  maxSize: (file) => {
                                    if (!file[0]) return true;
                                    return (
                                      file[0]?.size <= 5 * 1024 * 1024 ||
                                      "File size should be less than 5MB"
                                    );
                                  },
                                },
                              })}
                              onChange={handleImageChange}
                            />
                          </div>
                          {/* <button type="button" className="btn btn-dark">Change Image
                                                    </button> */}
                        </div>
                      </div>
                    ) : (
                      <input
                        className="form-control-file py-2"
                        type="file"
                        {...register("imageFile", {
                          validate: {
                            maxSize: (file) => {
                              if (!file[0]) return true;
                              return (
                                file[0]?.size <= 5 * 1024 * 1024 ||
                                "File size should be less than 5MB"
                              );
                            },
                          },
                        })}
                      />
                    )}
                  </div>
                </Card.Body>

                <div className="card-footer d-flex">
                  <Button type="submit" className="ms-auto bg-dark border-0">
                    Save
                  </Button>
                </div>
              </Card>
            </form>
          </ReactModal>
        )}
        {/* <button onClick={async ()=>{return(<Toast icon="success" title="success" iconcolor="green"></Toast>)}}>click</button> */}
        <Button
          type="button"
          className="bg-dark p-2 my-1 mb-2 ms-auto text-white rounded border-0"
          onClick={() => {
            openFormEditor({});
          }}
        >
          Add Configuration
        </Button>
      </div>

      <div className="col-12 mx-2 border-top" style={{ minHeight: "600px" }}>
        <Table striped bordered hover className="text-center">
          <thead>
            <tr>
              <th>S.No.</th>
              <th></th>
              <th>Key</th>
              <th>Key Value</th>
              <th>Value</th>
              <th colSpan="2">Action</th>
            </tr>
          </thead>
          <tbody>
            {configuration.map((x, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {x.imagePath ? (
                      <img
                        className=""
                        alt="Uploaded Data"
                        src={url + x.imagePath}
                        // src={getImageUrl(x.id)}
                        style={{ height: "70px", width: "90px" }}
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  <td>{x.itemKey}</td>
                  <td>{x.itemKeyValue}</td>
                  <td>{x.itemValue}</td>
                  <td>
                    <Button
                      type="button"
                      variant="outline-danger"
                      className="mt-2"
                      onClick={() => deleteConfiguration(x.id)}
                    >
                      Delete
                    </Button>
                  </td>
                  <td>
                    <Button
                      type="button"
                      variant="outline-warning"
                      className="mt-2"
                      onClick={() => updateConfiguration(x)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Configuration;
