import { useEffect, useState } from "react";
import HTMLRenderComp from "../Components/HTMLRenderComp";
import { useParams } from "react-router-dom";
import url from "../env";
import axios from "axios";
import { FnNotificationUI } from "../Utilities";
import { Link } from "react-router-dom";
import { GlobalSharedReduxFn } from "../Context";
import { useDispatch } from "react-redux";
import { useLoaderService } from "../Utilities/LoaderService";

const ServicePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [serviceData, setServiceData] = useState(null);
  const { startLoader, stopLoader } = useLoaderService();
  // const url = "https://localhost:44397/";
  // const url = "https://evisa-dev-api.cloud7info.com/";
  // const [htmlContent, setHtmlContent] = useState(serviceData.description);
  const getService = async () => {
    try {
      // dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
      startLoader();
      const response = await axios.get(url + "api/ourservices");
      const data = response.data.find((value) => value.id === Number(id));
      setServiceData(data);
    } catch (error) {
      console.log("error", error);
      FnNotificationUI.notifyError([], "Network Error");
    } finally {
      // dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
      stopLoader();
    }
  };

  useEffect(() => {
    getService();
  }, [id]);

  return (
    <>
      {/* {console.log(htmlContent, 'html')} */}
      <div className="row conatainer-fluid mx-0 px-0 bg-white">
        {/* <img src={serviceData.imagePath} style={{ width: '100%', height: '400px', display: 'flex', }}></img> */}

        <div className="col-lg-4 col-md-6 col-sm-12 border p-3">
          <img
            src={`${url}${serviceData?.imagePath}`}
            // src={getImageUrl(serviceData.id)} {`${url}${service?.imagePath}`}
            style={{ width: "100%", maxHeight: "400px", display: "flex" }}
          ></img>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12 border">
          <h1 className="my-3">{serviceData?.displayName}</h1>
          <HTMLRenderComp htmlContent={serviceData?.description} />
          {console.log(serviceData)}
          <div className="d-flex my-5 justify-content-end">
            <Link to={`/apply/${serviceData?.displayName}`}>
              <button
                type="button"
                className="btn bg-primary text-white ms-auto mt-auto me-3 px-5"
              >
                Apply Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicePage;
