import { Controller, useForm } from "react-hook-form";
import { TextControl } from "../Components";
import { useParams } from "react-router-dom";
import { ButtonDark } from "../Components";
import { Typeahead } from "react-bootstrap-typeahead";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../env";
import { FnHttpService, FnNotificationUI } from "../Utilities";
import { useLoaderService } from "../Utilities/LoaderService";
import { useNavigate } from "react-router-dom";
import showPopUp from "../Utilities/ShowPopUp";

function Apply() {
  const [serviceOptions, setServiceOptions] = useState([]);
  const { startLoader, stopLoader } = useLoaderService();
  const navigate = useNavigate();
  const { applyFor } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      applyFor,
    },
  });

  async function handlesubmit(data) {
    try {
      const confirmed = await showPopUp({
        title: `Do you want to Continue ?`,
        text: "You won't be able to change this!",
        icon: "question",
        confirmButtonColor: "#1e7e34",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Continue !`,
        showCancelButton: true,
      });
      if (!confirmed) {
        return;
      }
      startLoader();
      data.imageFile = data.imageFile[0];
      data.formType = "user";
      const formData = new FormData();
      FnHttpService.toFormData(formData, data);
      const response = await axios.post(url + "api/SubmissionForm", formData);
      console.log(response);
      FnNotificationUI.notifySuccess([], "Applied Successfully !!");
      navigate("/");
    } catch (error) {
      console.log("error", error);
      FnNotificationUI.notifyError([], "Network Error !!");
    } finally {
      stopLoader();
    }
  }

  const getServices = async () => {
    try {
      startLoader();
      const response = await axios.get(url + "api/ourservices");
      const data = response.data;
      const services = [];
      data.forEach((value) => {
        if (value.status === 0) {
          services.push(value.displayName);
        }
        setServiceOptions(services);
      });
    } catch (error) {
      console.log("Error", error);
      FnNotificationUI.notifyError([], "Network Error");
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  return (
    <div className="row container-fluid p-5 border-bottom d-flex justify-content-center m-auto">
      <div className="col-lg-4 col-sm-12 pt-3 pb-3 shadow-lg bg-dark rounded-start text-white">
        <h2 className="text-center mt-4 p-5">Application Form</h2>
        <div className="p-5 lh-5 textJustify">
          Thank you for your interest. Please fill out this form, and one of our
          support executives will connect with you shortly.
        </div>
      </div>
      <div className="col-lg-6 pt-3 pb-5 px-5 rounded-end border shadow">
        <form onSubmit={handleSubmit(handlesubmit)}>
          <Controller
            name="applyFor"
            control={control}
            rules={{ required: { value: true, message: "Required" } }}
            render={({ field }) => (
              <div className="mb-2">
                <label className="form-label">Apply for</label>
                <Typeahead
                  clearButton
                  id="applyFor"
                  options={serviceOptions}
                  {...field}
                  onChange={(selectOption) => {
                    if (selectOption.length) {
                      setValue("applyFor", selectOption[0]);
                    } else {
                      setValue("applyFor", "");
                    }
                  }}
                  defaultInputValue={applyFor}
                  placeholder="Select Visa type"
                  style={{ border: "1px solid black", borderRadius: "6px" }}
                />
              </div>
            )}
          />
          {errors["applyFor"] && (
            <div className="small text-danger">
              {errors["applyFor"]?.message || errors["applyFor"]?.type}
            </div>
          )}

          <TextControl
            lblText="Name"
            name="name"
            formReg={register("name", {
              required: {
                value: true,
                message: "Required",
              },
              maxLength: {
                value: 25,
                message: "Name Should be less then 25 Characters",
              },
              minLength: {
                value: 3,
                message: "Name Should be minimum have 3 Characters",
              },
            })}
            errorObj={errors}
            placeholder="Enter Name"
            disable={false}
          />
          <Controller
            name="gender"
            control={control}
            rules={{ required: { value: true, message: "Required" } }}
            render={({ field }) => (
              <div className="mb-2">
                <label className="form-label">Gender</label>
                <Typeahead
                  clearButton
                  id="gender"
                  options={["Male", "Female", "Other"]}
                  {...field}
                  onChange={(selectedOption) =>
                    setValue("gender", selectedOption[0])
                  }
                  style={{ border: "1px solid black", borderRadius: "6px" }}
                  placeholder="Select Gender"
                />
              </div>
            )}
          />
          {errors["gender"] && (
            <div className="small text-danger">
              {errors["gender"]?.message || errors["gender"]?.type}
            </div>
          )}

          <TextControl
            lblText="Age"
            name="age"
            formReg={register("age", {
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Minimum Age Should be Greater then 0",
              },
              max: {
                value: 150,
                message: "Maximum Age Should be less then 150",
              },
            })}
            errorObj={errors}
            placeholder="Enter Age"
            type="number"
          />

          <TextControl
            lblText="Phone Number"
            name="phone"
            formReg={register("phone", {
              required: {
                value: true,
                message: "Required",
              },
              minLength: {
                value: 9,
                message: "Invalid Phone Number",
              },
              maxLength: {
                value: 11,
                message: "Invalid Phone Number",
              },
            })}
            errorObj={errors}
            placeholder="Enter Phone Number"
            disable={false}
          />
          <TextControl
            lblText="Email"
            name="email"
            formReg={register("email", {
              required: {
                value: true,
                message: "Required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Not a valid Email",
              },
            })}
            errorObj={errors}
            placeholder="Enter Email"
          />
          <TextControl
            lblText="Upload Image"
            name="imageFile"
            formReg={register("imageFile", {
              required: {
                value: true,
                message: "Required",
              },
              validate: (value) => {
                if (value?.[0].size > 308224) {
                  return "Image should be less then 300kb";
                }
                return true;
              },
            })}
            errorObj={errors}
            type="file"
            inputProps={{ accept: "image/*" }}
          />

          <TextControl
            lblText="Destination"
            errorObj={errors}
            type="text"
            name="destination"
            formReg={register("destination", {
              required: true,
            })}
            placeholder="Enter Destination"
          />

          <TextControl
            lblText="Valid From"
            errorObj={errors}
            type="date"
            name="validFrom"
            formReg={register("validFrom", {
              required: { value: true, message: "Required" },
              validate: (value) => {
                if (new Date(value) < new Date()) {
                  return "Invalid Date Selection";
                }
                return true;
              },
            })}
          />

          <TextControl
            lblText="Valid To"
            errorObj={errors}
            type="date"
            name="validTo"
            formReg={register("validTo", {
              required: { value: true, message: "Required" },
              validate: (value) => {
                if (value < watch("validFrom")) {
                  return "End Date Should be Greater then Start Date";
                }
                return true;
              },
            })}
          />

          <ButtonDark type="submit" className="mt-2 m-auto py-2 px-5">
            Submit
          </ButtonDark>
        </form>
      </div>
    </div>
  );
}

export default Apply;
