const mode = "prod";

let url;
mode === "dev"
  ? (url = "https://evisa-dev-api.cloud7info.com/")
  : mode === "local"
  ? (url = "https://localhost:44397/")
  : (url = "https://api.bedco-consult.com/");

export default url;
