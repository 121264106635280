import { Link } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import { AiFillGooglePlusSquare } from "react-icons/ai";
import { FaSquareInstagram } from "react-icons/fa6";

import url from "../env";
const Footer = () => {
  const { pathname } = useLocation();
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  // const url = 'https://localhost:44397/';
  // let url = "https://evisa-dev-api.cloud7info.com/";

  const [configuration, setConfiguration] = useState([]);

  useEffect(() => {
    getServices();
    getConfigurationList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getServices = async () => {
    try {
      const response = await axios.get(url + "api/ourservices");
      const data = response.data;
      console.log("service", data);
      setServices(data.filter((x) => x.status === 0));
    } catch (error) {
      console.log("getService", error);
    }
  };

  const getConfigurationList = async () => {
    try {
      const response = await axios.get(url + "api/Config");
      const data = response.data.data;
      setConfiguration(data);
    } catch (error) {
      console.log("getConfigList", error);
    }
  };

  return (
    <>
      <div className="row container-fluid px-0 mx-0">
        <section className="col-lg-12 row col-md-12 col-sm-12 pt-5 mx-0 px-0">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <h4 className="d-flex px-3 align-items-left">
              Do you have any Question or want more information ?{" "}
            </h4>
            <h4 className="d-flex px-3 align-items-left">Contact us now</h4>
            <div className="row px-0 mx-0 mt-4 d-flex justify-content-sm-left justify-content-center  me-0 pe-0">
              <div className="col-lg-6 col-md-12 col-sm-12 py-1 d-flex align-items-center justify-content-left">
                <div
                  className="bg-white rounded-circle shadow fs-4 d-flex  align-items-center  justify-content-center"
                  style={{ height: "50px", width: "50px" }}
                >
                  <FaPhoneVolume color="#09af20" size={32} />
                </div>
                <h6 className="mx-2">
                  {configuration[0]?.itemValue} /{configuration[1]?.itemValue}
                </h6>
              </div>

              <div className="col-lg-6 mx-0 px-1 col-md-12 col-sm-12 py-1 d-flex align-items-center justify-content-left justify-content-sm-left">
                <div
                  className="bg-white mx-1  rounded-circle shadow fs-4 d-flex  align-items-center  justify-content-center"
                  style={{ height: "50px", width: "50px" }}
                >
                  <FaEnvelopeOpenText size={32} />
                </div>
                <h5>
                  <a
                    href="mailto:bedcoconsultlimited@gmail.com"
                    className="text-decoration-none"
                  >
                    {configuration[2]?.itemValue}{" "}
                  </a>
                </h5>
              </div>
              <div className="m-0 p-0">
                <h4 className="m-4 ms-3"> Follow Us</h4>
                <div className="my-3 ms-5">
                  <Link
                    className=""
                    to="https://www.facebook.com/Bedcoconsult/"
                  >
                    <FaSquareFacebook color="green" size={30} />
                  </Link>
                  <Link
                    className="ms-2"
                    to="https://twitter.com/i/flow/login?redirect_after_login=%2FBedcoConsult"
                  >
                    <FaSquareTwitter color="green" size={30} />
                  </Link>
                  <Link className="ms-2" to="/">
                    <AiFillGooglePlusSquare color="green" size={30} />
                  </Link>
                  <Link
                    className="ms-2"
                    to="https://www.instagram.com/bedcoconsult/"
                  >
                    <FaSquareInstagram color="green" size={30} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 row px-5">
            <div className="col-lg-6 col-md-6 col-sm-12 text-start">
              <ul className="list-unstyled">
                <li className="p-2 fs-5">
                  <Link className="text-dark text-decoration-none" to="/">
                    Home
                  </Link>
                </li>
                <li className="p-2 fs-5">
                  <Link
                    className="text-dark text-decoration-none"
                    to="/aboutus"
                  >
                    About Us
                  </Link>
                </li>
                <li className="p-2 fs-5">
                  <Link
                    className="text-dark text-decoration-none"
                    to="/contactus"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="p-2 fs-5">
                  <Link
                    className="text-dark text-decoration-none"
                    to="https://bedcoconsultlimite.wixsite.com/bedco/blog"
                  >
                    FAQ's
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-start ">
              <label className="list mt-3 fs-5">Our Services</label>
              {services.map((x, index) => {
                return (
                  <ul key={index} className="list-group list-unstyled mt-1">
                    <li
                      className="list ps-3"
                      onClick={() =>
                        navigate(`/services/${x.id}`, { state: x })
                      }
                    >
                      <Link className="text-dark text-decoration-none">
                        {x.displayName}
                      </Link>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </section>
      </div>

      <div className="container">
        <div className="col-lg-12 border-top text-center py-2">
          Copyright <FaRegCopyright /> {new Date().getFullYear()} Bedco Consult
          Ltd. All right reserved
        </div>
      </div>
    </>
  );
};
export default Footer;
