const StepsCard = ({ num, title, text, bgcolor }) => {
    return (<>
        <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center  justify-content-sm-center mx-0 px-0 my-2" >
            <div className=" card  pt-3 rounded-3 " style={{ height: "250px", width: "95%" }}>
                <div className="mx-auto rounded-circle  text-white fs-1 text-center fw-bold d-flex align-self-center justify-content-center align-items-center"
                    style={{ height: '50px', width: '50px', backgroundColor: bgcolor }}>{num}</div>
                <div className="card-body">
                    <h2 className=" fs-3 fw-bold py-3 px-0">{title}</h2>
                    <h6 className="card-text fw-light  fs-6 ">{text}</h6>
                </div>
            </div>
        </div>

    </>)
}
export default StepsCard;