import React from "react";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";
import banner1 from "../Assets/banner-1.png";
import banner2 from "../Assets/wp3841143-passport-wallpapers.jpg";
import { Link } from "react-router-dom";

const Slider = () => {
  const slides = [
    { key: 1, url: banner1, style: {} },
    {
      key: 2,
      url: banner2,
      style: { opacity: "100%", backgroundColor: "#1c2539c2", color: "white" },
    },
  ];

  return (
    <div
      className="container bg-light px-0 mx-0"
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <Swiper
        modules={[Navigation, Pagination, Autoplay, A11y]}
        spaceBetween={100}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.key}>
            <div
              className="row container-fluid px-0 mx-0"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${slide.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="col-lg-6 col-md-12 col-sm-12 fw-bold p-5">
                <div className="mx-lg-5 mx-sm-1 rounded" style={slide.style}>
                  <div className="p-3 row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <h1 className="my-0 py-0 display-3 fw-medium text-lg text-sm-md">
                        We make your{" "}
                        <label className="text-success">Visa</label> process
                        <br /> <label>easy</label>
                      </h1>
                    </div>
                    <div className="text-center d-flex">
                      <Link to="/apply/">
                        <button className="py-2 my-2 ms-auto px-4 bg-primary border-0 rounded text-white fs-5">
                          Apply Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12"> </div>
              {/* <img
                                src={}
                                alt={`Slide ${slide.key}`}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: '100%',
                                }}
                            /> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className="swiper-button-prev swiper1-button-prev"></div>
            <div className="swiper-button-next swiper1-button-next"></div> */}
    </div>
  );
};

export default Slider;
