const AboutCard = ({ title, text1, text2, image, isRight, isHeader = false }) => {


    return (
        <div className={` col-lg-12 col-md-12 col-sm-12  ${isHeader ? "bg-light" : "bg-white"}`}>
            <div className="row  container-fluid ">
                <div className="col-lg-1  col-md-1 col-sm-1"></div>
                <div className={`col-lg-5  py-4 ${isRight ? 'order-lg-2' : ''}`}>
                    <label className={`${text2 ? "mt-1" : "mt-5"} fw-semibold pt-3 ms-4 ${isHeader ? "h1" : "h3"}`}>{title}</label>
                    <p className="mt-3 fs-5 ms-4">{text1}</p>
                    <p className="mt-3 fs-5 ms-4">{text2}</p>
                </div>

                <div className={`col-lg-5 d-flex  ${isHeader ? 'py-5' : 'py-2'} ${isRight ? 'order-lg-1 justify-content-lg-end justify-content-sm-center' : ''}`}>
                    <img className={`my-auto ${isHeader ? 'mx-auto img-fluid' : 'me-auto img-thumbnail'}`} src={image} alt="about" style={{ maxWidth: isHeader ? "350px" : "100%" }} />

                </div>
                {/* <div className="col-lg-1 col-md-1 col-sm-1"></div> */}
            </div>
        </div >
    );
};

export default AboutCard;
