const statusEnum = ['Active', 'Inactive', 'Archive']
const categoryEnum = ['Holiday', 'General', 'Critical']
const calendarViewEnum = [{ key: "day", value: "Day" }, { key: "week", value: "Week" }, { key: "month", value: "Month" }, , { key: "agenda", value: "Agenda" }]

export const WebConfigAdmin = {
    // contextKeys: {
    //     globalSharedRedux: "GlobalSharedRedux"
    // },
    regularExp: {
        alphaNumericWithNoSpace: /^[a-zA-Z0-9-]+$/i,
        onlyNumbersWithNoSpace: /^[0-9\b]+$/i,
        AlphaNumericWithSpace: /^[a-zA-Z0-9]+[/\S+@\S+.\S+/]+$/i,
        AlphnumericEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    },
    apiEndPoints: {
        webNavigation: {
            controller: "adminNavigation",
            methods: {
                navigationMenuGet: "navigationMenuGet",

            }
        },


        tenantManager: {
            controller: "tenantmanager",
            methods: {
                get: "",
                create: "",
                delete: "",
                update: "",

            },
        },
        RoleManager: {
            controller: "tenantRoleMaster",
            methods: {
                get: "",
                create: "",
                delete: "",
                update: "",

            },
        },
        RoleClaim: {
            controller: "tenantRoleClaims",
            methods: {
                get: "",
                create: "",
                delete: "",
                update: "",

            },
        },
        tenantMicroSvc: {
            controller: "TenantMicroSvc",
            methods: {
                get: "",
                create: "",
                delete: "",
                update: "",

            },

        },
        microsvcmaster: {
            controller: "MicroSVCMaster",
            methods: {
                get: "",
                create: "",
                delete: "",
                update: "",
            }
        },
        microsvcclaimmaster: {
            controller: "MicroSvcClaimMaster",
            methods: {
                get: "",
                create: "",
                delete: "",
                update: "",

            },
        },
        tenantSetting: {
            controller: "TenantSetting",
        },
        tenantAuthSetting: {
            controller: "TenantAuthSetting",
        }
    },
    statusEnum: statusEnum,
    categoryEnum: categoryEnum,
    calendarViewEnum: calendarViewEnum
}