import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import HomePage from "./Pages/HomePage";
import AboutUs from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Dashboard from "./Pages/Admin/Dashboard";
import OurServicesPage from "./Pages/OurServicesPage";
import ServicePage from "./Pages/ServicePage";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ReduxStoreContext } from "./Context/ReduxStoreContext";
import UserProfile from "./Pages/Userprofile";
import UserManager from "./Components/UserManager";
import ServiceManager from "./Components/ServiceManager";
import Configuration from "./Pages/Admin/Configuration";
// import ViewMore from "./Components/ViewMore";
import ServiceList from "./Components/ServiceList";
import Loader from "./Components/Loader";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import ClientConfiguration from "./Pages/Admin/ClientConfiguration";
import Apply from "./Pages/Apply";

function App() {
  const { persistor, reduxStore } = ReduxStoreContext();

  return (
    <div className="App">
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Loader />
            <Header />
            <ReactNotifications />
            <Routes>
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUsPage />} />
              <Route path="/signin" element={<HomePage />} />
              <Route path="/admin" element={<Dashboard />}>
                <Route index element={<UserManager />} />
                <Route path="user-manager" element={<UserManager />} />
                <Route path="service-manager" element={<ServiceManager />} />
                <Route path="configuration" element={<Configuration />} />
                <Route
                  path="client-configuration"
                  element={<ClientConfiguration />}
                />
              </Route>
              <Route path="/services" element={<OurServicesPage />} />
              <Route path="/" element={<HomePage />} />
              {/* <Route path="/admin" element={<Dashboard />} /> */}
              <Route path="/userprofile" element={<UserProfile />} />
              <Route path="/services/:id" element={<ServicePage />} />
              <Route path="/allServices" element={<ServiceList />} />
              <Route path="/apply/:applyFor" element={<Apply />} />
              <Route path="/apply" element={<Apply />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
