import { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useForm, Controller } from "react-hook-form";
import { ReactModal, TextControl } from "../Components";
import Card from "react-bootstrap/Card";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FnHttpService, FnNotificationUI } from "../Utilities";
import { useSelector } from "react-redux";
import { WebConfig } from "../WebConfig";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import url from "../env";
import showPopUp from "../Utilities/ShowPopUp";
import { useLoaderService } from "../Utilities/LoaderService";

const ServiceManager = () => {
  const userToken = useSelector(
    (state) => state[WebConfig.contextKeys.currentUserRedux].authToken
  );
  // const url = "https://" + url;

  // const url = "https://localhost:44397/";
  // const url = "http://api.bedco-consult.com/";
  const [isModalOpen, setModalState] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [servicesList, setServicesList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { startLoader, stopLoader } = useLoaderService();

  useEffect(() => {
    getServicesList();
  }, []);

  const onEditorStateChange = (state) => {
    setEditorState(state);
    const textEditor = state.getCurrentContent();
    console.log(textEditor);
    setValue("description", textEditor.getPlainText());
  };

  const getServicesList = async () => {
    startLoader();
    try {
      const response = await axios.get(url + "api/ourservices");
      console.log("get service", response.data);
      setServicesList(response.data.sort((a, b) => a.status - b.status));
    } catch (error) {
      FnNotificationUI.notifyError([], "Network Error");
      console.error("Error:", error.message);
    } finally {
      stopLoader();
    }
  };

  const openFormEditor = (item) => {
    if (item.imagePath) {
      setImageUrl(url + item?.imagePath);
    }
    if (item.description) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(item.description))
        )
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
    reset(item || {});
    setModalState(true);
  };

  const closeModal = () => {
    reset({});
    setIsEdit(false);
    setModalState(false);
    setImageUrl(null);
  };

  const SubmitService = async (data) => {
    try {
      console.log(data, "service");
      const result = await showPopUp({
        title: `Are you sure to ${data.id ? "Update" : "Create"} ?`,
        text: "Don't worry ! you can change this later",
        icon: "question",
        confirmButtonColor: "#1e7e34",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${data.id ? "Update" : "Create"}!`,
        showCancelButton: true,
      });

      const description = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      if (result) {
        startLoader();
        let formData = new FormData();
        FnHttpService.toFormData(formData, { ...data, description });

        if (data.id) {
          const response = await axios.put(
            url + "api/ourservices/" + data.id,
            formData,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          console.log("update", response);
        } else {
          const response = await axios.post(url + "api/ourservices", formData);
          console.log("Create", response);
        }
        showPopUp({
          title: "Form submitted successfully!",
          icon: "success",
          willClose: () => {
            FnNotificationUI.notifySuccess(
              [],
              `${data.id ? "Updated" : "Created"} Successfully`
            );
            getServicesList();
            setModalState(false);
            reset({});
          },
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
      showPopUp({
        icon: "error",
        title: "Error",
        text: "Error posting data",
      });
      FnNotificationUI.notifyError(
        [],
        `Failed to ${data.id ? "Update" : "Create"}`
      );
    } finally {
      stopLoader();
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setImageUrl(fileURL);
      setValue("imageFile", file);
    }
  };

  const updateService = (service) => {
    console.log(service, "editservice");
    setIsEdit(true);
    openFormEditor(service);
  };

  const deleteService = async (service) => {
    try {
      const result = await showPopUp({
        title: "Are you sure to delete?",
        text: "You won't be able to change this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#1e7e34",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      });

      if (result) {
        // dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        startLoader();
        const response = await axios.put(
          url + "api/ourservices/deleteService/" + service.id,
          {},
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response) {
          console.log("delete", response);
          showPopUp({
            title: "Form submitted successfully!",
            icon: "success",
            willClose: () => {
              FnNotificationUI.notifySuccess([], "Deleted Successfully");
              getServicesList();
            },
          });
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      showPopUp({
        icon: "error",
        title: "Error",
        text: "Error posting data",
      });
      FnNotificationUI.notifyError([], "Failed to Delete");
    } finally {
      // dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
      stopLoader();
    }
  };

  return (
    <>
      <div className="d-flex">
        {isModalOpen && (
          <ReactModal
            onModalClose={() => {
              closeModal();
            }}
          >
            <form onSubmit={handleSubmit(SubmitService)}>
              <Card className="bg-white">
                <Card.Header className="bg-white">
                  <h3>{isEdit ? "Update" : "Create"} Service</h3>
                </Card.Header>
                <Card.Body>
                  <div className="px-4 border-rounded border-dark">
                    <TextControl
                      lblText="Display Name"
                      formReg={register("displayName", {
                        required: "Required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is only 100 char.",
                        },
                      })}
                      errorObj={errors}
                      name={"displayName"}
                    ></TextControl>

                    <TextControl
                      lblText="Short Description"
                      formReg={register("shortDescription", {
                        required: "Required",
                        maxLength: {
                          value: 250,
                          message: "Max Length is only 250 char.",
                        },
                      })}
                      errorObj={errors}
                      name={"shortDescription"}
                    ></TextControl>

                    {/* <TextControl lblText="Description" formReg={register("description", {
                                            required: "Required",
                                        })} errorObj={errors} name={"description"}></TextControl> */}

                    <div className="border my-2">
                      <Controller
                        name="description"
                        control={control}
                        // defaultValue={false}
                        rules={{ required: "This is required" }}
                        render={({ field }) => (
                          <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            editorStyle={{
                              minHeight: "20vh",
                              maxHeight: "25vh",
                              overflowY: "scroll",
                            }}
                            // {...field}
                            onEditorStateChange={onEditorStateChange}
                          />
                        )}
                      />
                      {errors.description && (
                        <p className="text-danger">{errors.Description}</p>
                      )}
                      {/* <div>{editorState.getCurrentContent()}</div> */}
                    </div>

                    {/* <TextControl lblText={'Image File'} type="file"
                                            formReg={register('imageFile')}
                                            errorObj={errors} name={"imageFile"} >

                                        </TextControl> */}
                    <label className="my-1">Image File</label>
                    <div className="row">
                      {/* {console.log(imageUrl, 'imag')} */}
                      {imageUrl && (
                        <div className="row">
                          <div className="col-4">
                            <img
                              src={imageUrl}
                              style={{ height: "150px", width: "200px" }}
                              alt="selected-file"
                            />
                          </div>
                          <div className="col-6 d-flex justify-content-left align-items-center">
                            <div className="input-group mb-3">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupFile01"
                              >
                                Change Image
                              </label>
                              <input
                                className="form-control-file py-2 d-none"
                                id="inputGroupFile01"
                                type="file"
                                {...register("imageFile", {
                                  validate: {
                                    maxSize: (file) => {
                                      if (!file[0]) return true;
                                      return (
                                        file[0]?.size <= 5 * 1024 * 1024 ||
                                        "File size should be less than 5MB"
                                      );
                                    },
                                  },
                                })}
                                onChange={handleImageChange}
                              />
                            </div>
                            {/* <button type="button" className="btn btn-dark">Change Image
                                                    </button> */}
                          </div>
                        </div>
                      )}
                      {!imageUrl && (
                        <div className="col-4">
                          <input
                            className="form-control-file py-2"
                            type="file"
                            {...register("imageFile", {
                              validate: {
                                maxSize: (file) => {
                                  if (!file[0]) return true;
                                  return (
                                    file[0]?.size <= 5 * 1024 * 1024 ||
                                    "File size should be less than 5MB"
                                  );
                                },
                              },
                            })}
                            onChange={handleImageChange}
                          />
                        </div>
                      )}
                      {/* <Controller
                                                name="image"
                                                control={control}
                                                render={({ field }) => (
                                                    <ImageCropper2 setValue={setValue} initialImageUrl={img1} />
                                                )}
                                            /> */}
                      {/* <ImageCropper2 setValue={setValue} initialImageUrl={img1} /> */}
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="d-flex">
                  <Button
                    type="submit"
                    className="ms-auto border bg-dark btn-lg"
                  >
                    Save
                  </Button>
                </Card.Footer>
              </Card>
            </form>
          </ReactModal>
        )}
        <Button
          type="button"
          className="bg-dark border-0 my-2 mx-2 ms-auto"
          onClick={() => {
            openFormEditor({});
          }}
        >
          Create Service
        </Button>
        {/* <Button type='button' className="btn btn-dark " onClick={() => FnNotificationUI.notifySuccess("Success")}>notify</Button> */}
      </div>
      <div className="col-12 mx-2 border-top" style={{ minHeight: "600px" }}>
        <Table striped bordered hover className="text-center">
          <thead>
            <tr className="">
              <th></th>
              <th>Sr Number</th>
              <th>Display Name</th>
              <th>Short Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {console.log(servicesList)}
            {servicesList.map((x, index) => {
              return (
                <tr key={index}>
                  <td>
                    {x.imagePath ? (
                      <img
                        alt="Uploaded Data"
                        src={url + x.imagePath}
                        // src={getImageUrl(x.id)}
                        style={{ height: "70px", width: "90px" }}
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  <td>{index + 1}</td>
                  <td>{x.displayName}</td>
                  <td>{x.shortDescription}</td>
                  <td>
                    {x?.status === 0
                      ? "Active"
                      : x?.status === 2
                      ? "Inactive"
                      : "Unapproved"}
                  </td>
                  <td>
                    <div className="d-flex">
                      <Button
                        type="button"
                        variant="outline-danger"
                        onClick={() => deleteService(x)}
                      >
                        Delete
                      </Button>
                      <Button
                        type="button"
                        variant="outline-warning"
                        className="ms-2"
                        onClick={() => updateService(x)}
                      >
                        Edit
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default ServiceManager;
