import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import url from "../env";
import { FnNotificationUI } from "../Utilities";
import { useLoaderService } from "../Utilities/LoaderService";
const ServiceList = () => {
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoaderService();
  // const url = "https://localhost:44397/";
  // const url = "https://evisa-dev-api.cloud7info.com/"

  const [servicesList, setServicesList] = useState([]);
  useEffect(() => {
    getServicesList();
  }, []);

  const getServicesList = async () => {
    try {
      startLoader();
      const response = await axios.get(url + "api/ourservices");
      const data = response.data;
      console.log("get service", data);
      setServicesList(data.filter((x) => x.status === 0));
    } catch (error) {
      console.log("error", error);
      FnNotificationUI.notifyError([], "Network Error");
    } finally {
      stopLoader();
    }
  };
  return (
    <div className="px-5">
      {/* <Table hover className='text-center'>
                <thead >
                    <tr>
                        <th></th>
                        <th>Display Name</th>
                        <th>Short Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody> */}
      {servicesList.map((x, index) => {
        return (
          <div className="card mb-3 bg-white">
            <div className="row">
              {/* <div className='card-body'> */}
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex ">
                {" "}
                <img
                  src={url + x.imagePath}
                  className="mx-auto my-auto"
                  style={{ maxWidth: "75%" }}
                />
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 border-danger">
                <div className="card-body">
                  <p className="card-title fs-3">{x.displayName}</p>

                  <div className="d-flex justify-content-center align-item-center">
                    <p className="card-text">{x.shortDescription}</p>
                  </div>
                </div>
                <button
                  className="mb-4 me-5 btn btn-success text-white d-flex mx-auto"
                  type="button"
                  onClick={() => navigate(`/services/${x.id}`, { state: x })}
                >
                  View
                </button>
              </div>
            </div>
          </div>

          //  {/* <tr key={index}>
          //     <td>
          //         <img className="" */}
          // {/* //             src={url + x.imagePath}
          // //             // src={getImageUrl(x.id)}
          // //             style={{ height: '70px', width: '90px' }} />
          // //     </td> */}
          // {/* //     <td>{x.displayName}</td>
          // //     <td>{x.shortDescription}</td>
          // //     <td><div className="d-flex ">
          // //         <Button type="button " className="bg-success border" onClick={() => navigate(`/services/${x.id}`, { state: x })}>View</Button>
          // //     </div></td>
          // // </tr> */}
        );
      })}
      {/* </tbody>
            </Table> */}
    </div>
  );
};

export default ServiceList;
