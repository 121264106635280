import StepsCard from "./StepsCard";

const Steps = () => {
  const steps = [
    {
      title: "Choose Visa Type",
      text: "Determine the visa type for your travel purpose",
      bgcolor: "#05c7c5",
    },
    {
      title: "Submit documents",
      text: "Collect all the required documents the process",
      bgcolor: "#c7ae05",
    },
    {
      title: "Payment Process",
      text: "Process payment as per the e-Visa process.",
      bgcolor: "#0a8df2",
    },
    {
      title: "E-Visa Delivery",
      text: "Collect your e-Visa through out portal",
      bgcolor: "#09af20",
    },
  ];
  return (
    <>
      <section
        className="col-lg-12 col-md-12 col-sm-12 px-0 mx-0 pb-3"
        style={{ backgroundColor: "#1c2539" }}
      >
        <div className="row container-fluid px-0 mx-0 ">
          <div className="text-white px-0 mx-0 ">
            <div className="d-flex">
              <h2 className="me-auto ms-3 my-3">
                Simple Steps and Trustworthy Visa
              </h2>
            </div>
            <div className="d-flex">
              <p className="me-auto ms-3">
                Our team of seasoned professionals understand the complexities
                of immigration laws and visa procedures.
              </p>
            </div>
            <div className="row mx-5 text-center d-flex justify-content-center justify-content-sm-center mx-sm-0">
              {steps.map((eachStep, index) => (
                <StepsCard
                  key={index}
                  num={index + 1}
                  title={eachStep.title}
                  text={eachStep.text}
                  bgcolor={eachStep.bgcolor}
                />
              ))}
              {/* <StepsCard
                num="2"
                title="Submit documents"
                text="Collect all the required documents the process"
                bgcolor="#c7ae05"
              />
              <StepsCard
                num="3"
                title="Payment Process"
                text="Process payment as per the e-visa process"
                bgcolor="#0a8df2"
              />
              <StepsCard
                num="4"
                title="E-Visa Delivery"
                text="Collect your e-visa through out portal"
                bgcolor="#09af20"
              /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Steps;
