const { default: classNames } = require("classnames");

const ButtonRoot = ({
  children,
  className,
  variant,
  gradient,
  ...otherProps
}) => (
  <button
    className={classNames(
      "btn",
      "ripple-effect",
      `btn-${variant}`,
      gradient ? "gradient" : "",
      className
    )}
    {...otherProps}
  >
    {children}
  </button>
);

export const ButtonWhite = ({ children, className, ...otherProps }) => (
  <ButtonRoot variant="white" className={className} {...otherProps}>
    {children}
  </ButtonRoot>
);

export const ButtonDark = ({ children, className, ...otherProps }) => (
  <ButtonRoot variant="dark" className={className} {...otherProps}>
    {children}
  </ButtonRoot>
);
export const ButtonPrimary = ({
  children,
  gradient = false,
  className,
  ...otherProps
}) => (
  <ButtonRoot
    className={className}
    gradient={gradient}
    variant="primary"
    {...otherProps}
  >
    {children}
  </ButtonRoot>
);

export const ButtonSecondary = ({
  children,
  gradient = false,
  className,
  ...otherProps
}) => (
  <ButtonRoot
    className={className}
    gradient={gradient}
    variant="secondary"
    {...otherProps}
  >
    {children}
  </ButtonRoot>
);

export const ButtonInfo = ({
  children,
  gradient = false,
  className,
  ...otherProps
}) => (
  <ButtonRoot
    className={className}
    gradient={gradient}
    variant="info"
    {...otherProps}
  >
    {children}
  </ButtonRoot>
);

export const ButtonWarning = ({
  children,
  gradient = false,
  className,
  ...otherProps
}) => (
  <ButtonRoot
    className={className}
    gradient={gradient}
    variant="warning"
    {...otherProps}
  >
    {children}
  </ButtonRoot>
);

export const ButtonDanger = ({
  children,
  gradient = false,
  className,
  ...otherProps
}) => (
  <ButtonRoot
    className={className}
    gradient={gradient}
    variant="danger"
    {...otherProps}
  >
    {children}
  </ButtonRoot>
);

export const ButtonSuccess = ({
  children,
  gradient = false,
  className,
  ...otherProps
}) => (
  <ButtonRoot
    className={className}
    gradient={gradient}
    variant="success"
    {...otherProps}
  >
    {children}
  </ButtonRoot>
);
