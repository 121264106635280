import AboutCard from "../Components/AboutCard";
import { useState, useEffect } from "react";
import axios from "axios";
import url from "../env";
import { FnNotificationUI } from "../Utilities";

const AboutUs = () => {
  // let url = "https://evisa-dev-api.cloud7info.com/"
  const [configuration, setConfiguration] = useState([]);

  const getConfigurationList = async () => {
    try {
      const response = await axios.get(url + "api/Config");
      const data = response.data.data;
      console.log("config in abtus", data);
      setConfiguration(data);
    } catch (error) {
      FnNotificationUI.notifyError([], "Network Error");
      console.log("Error About", error);
    }
  };
  useEffect(() => {
    getConfigurationList();
  }, []);

  return (
    <>
      <div>
        <AboutCard
          text1={configuration[8]?.itemValue}
          title={configuration[8]?.itemKeyValue}
          titletagclass="h1"
          image={`${url}${configuration[8]?.imagePath}`}
          isRight={false}
          isHeader={true}
        />
        <AboutCard
          text1={configuration[9]?.itemValue}
          title={configuration[9]?.itemKeyValue}
          image={`${url}${configuration[9]?.imagePath}`}
          isRight={true}
        />
        <AboutCard
          text1={configuration[10]?.itemValue}
          text2={configuration[11]?.itemValue}
          title={configuration[10]?.itemKeyValue}
          image={`${url}${configuration[10]?.imagePath}`}
          isRight={false}
        />
      </div>
      {/* <div className="row container-fluid bg-light text-center py-3">
            <h2 className='my-5'>Our Services By the Numbers</h2>
            <div className="row container-fluid  pb-5 mb-2 d-flex justify-content-center">
                <div className='col-lg-10 mx-auto col-md-10 col-sm-10 row d-flex justify-content-center justify-content-sm-center'>
                    <AboutCard2 image={pCard3} title="12 Global Offices" link="/" />
                    <AboutCard2 image={pCard2} title="7,600+ Employees" link="/" />
                    <AboutCard2 image={pCard1} title="205,000+ Customers" link="/" />
                </div>

            </div>
        </div> */}
    </>
  );
};
export default AboutUs;
