import { useEffect, useState } from "react";
import ClientCard from "../Components/ClientCard";
import axios from "axios";
import url from "../env";

const OurClients = () => {
  const [clients, setClients] = useState([]);
  // const url = "https://evisa-dev-api.cloud7info.com/";

  const getClientDetails = async () => {
    try {
      const response = await axios.get(`${url}api/Client`);
      setClients(response.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getClientDetails();
  }, []);

  return (
    <div className="container-fluid row my-3 ">
      <div className="my-5 row mx-0 px-0">
        <div className="col-lg-4 col-md-12 col-sm-12 px-5">
          <h1 className="my-3">Our Clients</h1>
          <p>
            {/* Since the inception of Bedco Consult Ltd in 2013, the company has
            been entrusted with planning, running and enhancing the immigration
            needs of various multinational firms. On our portfolio are the
            companies listed. */}
            Since its inception in 2013, Bedco Consult Ltd has been entrusted
            with planning, managing, and enhancing the immigration needs of
            various multinational firms. The listed companies are part of our
            portfolio.
          </p>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 mx-0 px-0">
          <div className="row container-fluid mx-0 px-0 justify-content-center justify-content-md-left align-items-center">
            {clients.map((eachClient) => (
              <ClientCard
                img={url + eachClient.imagePath}
                title={eachClient.itemKeyValue}
                text={eachClient.itemValue}
                key={eachClient.itemKey}
              />
            ))}
            {/* 
              Client 1 : KASAPREKO COMPANY LIMITED is a prominent and successful producer of alcoholic and non-alcoholic beverages in Ghana.
              Previous : is a leading and successful alcoholic and non - alcoholic beverage producer in Ghana.

              Client 2 : ROYAL CROWN PACKAGING provides a wide range of corrugated boxes and folding carton solutions, supplying a variety of standardized and custom cardboard box products to businesses nationwide and globally.
              Previous : provides a broad range of corrugated box and folding cartons solutions. They supply a variety of standardized and custom cardboard box products to businesses nationwide and across the globe.

              Client 3 : G&J TECHNICAL SERVICES LIMITED is a wholly Ghanaian-owned company solely dedicated to the sale, installation, and crucially, the maintenance of diesel engine-driven generating sets.
              Previous : is a wholly Ghanaian owned company.They are solely involved in the sale, installation and most importantly the maintenance of Diesel Engine Driven Generating sets.
            
            */}

            {/* <ClientCard
              img={Client1}
              title={"KASAPREKO COMPANY LIMITED"}
              text={
                "is a leading and successful alcoholic and non - alcoholic beverage producer in Ghana."
              }
            />
            <ClientCard
              img={Client2}
              title={"ROYAL CROWN PACKAGING"}
              text={
                "provides a broad range of corrugated box and folding cartons solutions. They supply a variety of standardized and custom cardboard box products to businesses nationwide and across the globe."
              }
            />
            <ClientCard
              img={Client3}
              title={"G&J TECHNICAL SERVICES LIMITED"}
              text={
                "is a wholly Ghanaian owned company.They are solely involved in the sale, installation and most importantly the maintenance of Diesel Engine Driven Generating sets."
              }
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
