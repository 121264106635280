const { default: classNames } = require("classnames");

export const ErrorControl = ({ name, errorObj }) => {
  return (
    <>
      {name && errorObj[name] && (
        <div className="small text-danger mt-1">
          {errorObj[name]?.message || errorObj[name]?.type}
        </div>
      )}
    </>
  );
};

export const TextControl = ({
  name,
  formReg,
  lblText,
  value,
  txtboxClass,
  errorObj,
  inputProps,
  type,
  placeholder,
  disable,
}) => {
  console.log(errorObj);
  return (
    <div className="mb-2">
      <label className="form-label">{lblText}</label>
      <input
        defaultValue={value}
        {...inputProps}
        {...(formReg && name ? { ...formReg } : {})}
        className={classNames(
          "form-control border-dark",
          txtboxClass,
          name && errorObj[name] ? "is-invalid" : ""
        )}
        type={type || "text"}
        placeholder={placeholder}
        disabled={disable}
      />
      {errorObj[name] && <ErrorControl name={name} errorObj={errorObj} />}
    </div>
  );
};
