import { FnParsing } from "../Utilities/FnParsing"

const DEFAULT_VALUE = {
    showPageLoader: false,
    expandSidebar: false,
    primaryMenu: [],
    primaryComplementaryMenu: [],
    secondaryMenu: [],
    flattenMenu: [],
    baseURL: "",
    tenantInfo: undefined

}
const ACTION_TYPE = {
    PAGE_LOADING_STATE_SET: "PAGE_LOADING_STATE_SET",
    EXPAND_SIDEBAR: "EXPAND_SIDEBAR",
    FLATTEN_MENU_SET: "FLATTEN_MENU_SET",
    TENANT_INFO_SET: "TENANT_INFO_SET"
    // PRIMARY_MENU_SET: "PRIMARY_MENU_SET",
    // SECONDARY_MENU_SET: "SECONDARY_MENU_SET",
    // PRIMARY_COMPLEMENTARY_MENU_SET: "PRIMARY_COMPLEMENTARY_MENU_SET",


}


export const GlobalSharedReduxFn = {
    pageLoadingStateSet: (showLoader) => {
        return {
            type: ACTION_TYPE.PAGE_LOADING_STATE_SET,
            payload: showLoader
        }
    },
    sidebarToggle: () => {
        return {
            type: ACTION_TYPE.EXPAND_SIDEBAR,
            //payload: boolState
        }
    },

    // primaryMenuSet: (menus) => {
    //     return {
    //         type: ACTION_TYPE.PRIMARY_MENU_SET,
    //         payload: menus
    //     }
    // },
    // secondaryMenuSet: (menus) => {
    //     return {
    //         type: ACTION_TYPE.SECONDARY_MENU_SET,
    //         payload: menus
    //     }
    // },
    // primaryMenuSet: (menus) => {
    //     return {
    //         type: ACTION_TYPE.PRIMARY_MENU_SET,
    //         payload: menus
    //     }
    // },
    flattenMenuSet: (menus) => {

        // let primary = FnParsing.flattenArray(menus.primary);
        // let secondary = FnParsing.flattenArray(menus.secondary);
        // let primaryComplementary = FnParsing.flattenArray(menus.primaryComplementary)
        let data = {
            primaryMenu: menus.primary,
            secondaryMenu: menus.secondary,
            primaryComplementaryMenu: menus.primaryComplementary,
            flattenMenu: FnParsing.arrayUniq(FnParsing.arrayJoin(
                FnParsing.arrayFlatten(menus.primary),
                FnParsing.arrayFlatten(menus.secondary),
                FnParsing.arrayFlatten(menus.primaryComplementary)), "url")
        }
        return {
            type: ACTION_TYPE.FLATTEN_MENU_SET,
            payload: data
        }
    },
    TenantInfoSet: (data) => {

        return {
            type: ACTION_TYPE.TENANT_INFO_SET,
            payload: data
        }
    },
}


export const GlobalSharedRedux = (state = DEFAULT_VALUE, action) => {

    let oState = undefined;
    switch (action.type) {
        case ACTION_TYPE.PAGE_LOADING_STATE_SET:
            oState = {
                ...state,
                showPageLoader: action.payload
            }
            break;
        case ACTION_TYPE.EXPAND_SIDEBAR:
            oState = {
                ...state,
                expandSidebar: !state.expandSidebar
            }
            break;

        case ACTION_TYPE.PRIMARY_MENU_SET:
            oState = {
                ...state,
                primaryMenu: action.payload
            }
            break;
        // case ACTION_TYPE.SECONDARY_MENU_SET:
        //     oState = {
        //         ...state,
        //         secondaryMenu: action.payload
        //     }
        //     break;
        case ACTION_TYPE.FLATTEN_MENU_SET:
            oState = {
                ...state,
                primaryMenu: action.payload.primaryMenu,
                secondaryMenu: action.payload.secondaryMenu,
                primaryComplementaryMenu: action.payload.primaryComplementaryMenu,
                flattenMenu: action.payload.flattenMenu
            }
            break;
        case ACTION_TYPE.TENANT_INFO_SET:
            oState = {
                ...state,
                tenantInfo: action.payload
            }


        default: break;
    }
    return oState || { ...state };
}