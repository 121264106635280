// import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// import { FnIO } from "Utilities";

import "./Modals.scss";
export const ReactModal = ({ children, onModalClose, size }) => {
    const defaultSize = "lg";
    const modalSize = size ? `modal-${size}` : `modal-${defaultSize}`;
    return (
        <div className="modal bootstrap-modal p-0 m-0 show d-block" aria-modal="true" role="dialog">
            <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
                <div className="modal-content shadow p-0 m-0">

                    <div className="modal-body p-0 m-0">

                        <button type="button " onClick={onModalClose} className="position-absolute top-0 end-0 m-2 btn-close close-button"></button>

                        {children}


                    </div>
                </div>
            </div>
        </div>
    )
}
