import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import { rootReduxReducers, SetTransform } from "./Reducers";

const persistInfo = {
    key: `${process.env.REACT_APP_REDUX_PERSIST_KEY}`,
    storage: storage,
    //transforms:[SetTransform]
    whitelist: ["GlobalCurrentUserRedux"]
};

export const ReduxStoreContext = (preloadedState) => {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const storeEnhancers = [middlewareEnhancer
        // (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' && process.env.NODE_ENV !== 'QA' && process.env.NODE_ENV !== 'PRODUCTION') ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ];

    const composedEnhancer = compose(...storeEnhancers);

    const pReducer = persistReducer(persistInfo, rootReduxReducers);


    const reduxStore = createStore(
        pReducer,
        preloadedState,
        composedEnhancer
    );

    const persistor = persistStore(reduxStore);

    // persistor.subscribe(() => console.log("store", persistor.getState()))
    return { persistor, reduxStore };
}
