import DOMPurify from "dompurify";
const _ = require('lodash')
function sanitizeText(dirtyString, hasHTML = false) {
    return DOMPurify.sanitize(dirtyString, {
        USE_PROFILES: { html: hasHTML },
        FORBID_TAGS: ['style', 'script'],
        FORBID_ATTR: ['style', 'script'],
        RETURN_DOM: false
    });
};
function arrayJoin(...others) {
    return _.concat(...others);

};
function arrayFlatten(array = [], key = "childrens") {

    function flatten(item) {

        const member = { ...item }; // copy
        delete member[key];

        if (!item[key] || !item[key].length) {
            return member; // return copied
        }

        // return copied, but pass original to flatMapDeep
        return [
            member,
            _.flatMapDeep(item[key], flatten)
        ];

    }

    return _.flatMapDeep(array, flatten)
};
function arrayUniq(array = [], uniqKey = "") {
    if (uniqKey) {
        return _.uniqBy(array, uniqKey)
    } else {
        return _.uniq(array);
    }
}
export const FnParsing = {
    sanitizeText,
    arrayFlatten,
    arrayJoin,
    arrayUniq
}