import Loadable from "react-loadable";
const LazyLoaderErrorComp = (e) => {
 
    if (e.error) {
        console.log(e)
        return <div>Error!</div>
    } else if (e.timedOut) {
        return <div>Taking a long time...</div>
        // } else if (e.pastDelay) {
        //     return <PageLoaderComp />
        // } 
    }
    // else if (e.pastDelay) {
    //     return <PageLoaderComp {...e} />;
    // } 
    else {
        // return <div {...e}>Loader</div>;

    }
}
export const FnLazyRender = (viewPath) => {

    const optLoad = {
        loader: () => new Promise((resolve, reject) => {
            setTimeout(() => resolve(import(`../${viewPath}`)), 500);
        })
    }
    return Loadable(Object.assign({
        loading: LazyLoaderErrorComp,
        render(loaded, props) {
            let Component = loaded.default;
            return <Component {...props} />;
        },
        // delay: 2000,
        // timeout: 10000,
    }, optLoad))
};