var apiEndPoints = {
    authentication: {
        controller: "auth",
        methods: {
            signIn: "signin",
            signUp: "signup",
        }
    },

}

export const eVisaPortalConfig = {
    postLoginUrl: "/private",
    apiEndPoints: apiEndPoints
}