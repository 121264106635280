import { WebConfigAdmin } from "./Portals/Admin/AdminWebConfig";
import { eVisaPortalConfig } from "./Portals/E-visa/eVisaPortalConfig";

export const WebConfig = {
    epochTicks: 621355968000000000,
    contextKeys: {
        globalSharedRedux: "GlobalSharedRedux",
        currentUserRedux: "GlobalCurrentUserRedux"
    },
    sharedApiEndPoints: {
        tenantResolver: {
            controller: "authenticate",
            methods: {
                signIn: "signIn",
            }
        },
    },

    eVisa: eVisaPortalConfig,
    administrator: WebConfigAdmin,
}