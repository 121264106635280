import * as CryptoJS from 'crypto-js';
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from 'uuid';
import { FnDateTime } from './FnDateTime';
export const FnIO = {
    encryptUsingAES: (strMessage) => {
        try {
            if (typeof strMessage != "string")
                strMessage = JSON.stringify(strMessage)

        } catch (e) {
            strMessage = "";
        }
        return CryptoJS.AES.encrypt(strMessage, process.env.REACT_APP_ENCRYPT_KEY).toString();

    },
    decryptUsingAES: (strEncryptedMessage) => {
        let strDescryptedMesssage = "";
        try {
            const decrypted = CryptoJS.AES.decrypt(strEncryptedMessage, process.env.REACT_APP_ENCRYPT_KEY);
            strDescryptedMesssage = decrypted.toString(CryptoJS.enc.Utf8);
        } catch (e) {

        }
        return strDescryptedMesssage;
    },
    encryptUsingSHA256: (strMessage) => {
        return CryptoJS.SHA256(strMessage, process.env.REACT_APP_ENCRYPT_KEY_SHA).toString();
    },
    uriValidate: (url) => {
        const parsed = new URL(url)
        return ['https:', 'http:'].includes(parsed.protocol)
    },
    newKey: (startTag = "key") => {
        return startTag + "_" + (FnDateTime.currentTicks()).toString() + uuidv4();
    },
    newGUID: () => uuidv4(),

   
    resizeImageFileAsync: (file, { maxWidth, maxHeight, compressFormat = 'PNG', quality = 100, rotation = 0, outputType = 'file', minWidth = null, minHeight = null }) => {
        /*     Resizer.imageFileResizer(
                 file, // Is the file of the image which will resized.
                 maxWidth, // Is the maxWidth of the resized new image.
                 maxHeight, // Is the maxHeight of the resized new image.
                 compressFormat, // Is the compressFormat of the resized new image. Can be either JPEG, PNG or WEBP.
                 quality, // A number between 0 and 100. Used for the JPEG compression.(if no compress is needed, just set it to 100)
                 rotation, //Degree of clockwise rotation to apply to the image. Rotation is limited to multiples of 90 degrees.(if no rotation is needed, just set it to 0) (0, 90, 180, 270, 360)
                 responseUriFunc, // Is the callBack function of the resized new image URI.
                 outputType, // Can be either base64, blob or file.(Default type is base64)
                 minWidth, // Is the minWidth of the resized new image.
                 minHeight // Is the minHeight of the resized new image.
               );
        */
        return new Promise((resolve) => {
            Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, quality, rotation,
                (uri) => {
                    const fileChanged = Object.assign(file, {
                        preview: URL.createObjectURL(uri)
                    })
                    resolve(fileChanged);
                },
                outputType, minWidth,
                minHeight
            )
        })
    }
}