import { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
// import { Button } from 'react-bootstrap';
import { ButtonDark, ButtonDanger } from "../Components";
import rootUrl from "../env";
import { FnNotificationUI } from "../Utilities";
const OurServicesPage = () => {
  const [TestData, setTestData] = useState([]);

  useEffect(() => {
    userTestData();
  }, []);

  const userTestData = async () => {
    try {
      const response = await axios.get(`${rootUrl}api/ourservices`);
      console.log("userTestData", response.data);
      setTestData(response.data);
    } catch (error) {
      console.log("error", error);
      FnNotificationUI.notifyError([], "Network Error");
    }
  };
  //   let rootUrl = "https://evisa-dev-api.cloud7info.com/";
  return (
    <>
      <Table striped bordered hover className="text-center">
        <thead>
          <tr>
            <th></th>
            <th>Display Name</th>
            <th>Id</th>
            <th>Short Description</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {TestData.map((x, index) => {
            return (
              <tr key={index}>
                <td>
                  <img
                    className=""
                    src={rootUrl + x.imagePath}
                    style={{ height: "70px", width: "70px" }}
                  />
                </td>
                <td>{x.displayName}</td>
                <td>{x.id}</td>
                <td>{x.shortDescription}</td>
                <td>{x.description}</td>
                <td>
                  <ButtonDanger variant="outline-danger">Delete</ButtonDanger>
                  <ButtonDark variant="outline-warning" className="ms-2">
                    Edit
                  </ButtonDark>
                </td>
              </tr>
            );
          })}
          ;
        </tbody>
      </Table>
    </>
  );
};

export default OurServicesPage;
