import { FaHeadset, FaWhatsapp } from "react-icons/fa6";
import call from "../Assets/call button black.png";
import customer from "../Assets/custmar support.png";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../env";
import { FnNotificationUI } from "../Utilities";
import { useLoaderService } from "../Utilities/LoaderService";

const ContactUsPage = () => {
  // const url = 'https://localhost:44397/';
  // let url = "https://evisa-dev-api.cloud7info.com/";
  const [configuration, setConfiguration] = useState([]);
  const { startLoader, stopLoader } = useLoaderService();

  useEffect(() => {
    getConfigurationList();
  }, []);

  const getConfigurationList = async () => {
    try {
      startLoader();
      const response = await axios.get(url + "api/Config");
      const data = response.data.data;
      setConfiguration(data);
    } catch (error) {
      console.log("Error in ContactUs", error);
      FnNotificationUI.notifyError([], "Network Error");
    } finally {
      stopLoader();
    }
    // axios.get(url + "api/Config").then((response) => {
    //   const data = response.data.data;
    //   setConfiguration(data);
    // });
  };
  return (
    <>
      <div className="">
        <div className="row container-fluid text-white bg-dark">
          <div className="col-lg-2 col-md-2 col-sm-0"></div>
          <div className="col-lg-4 col-md-6 col-sm-12 pt-5 mt-5 mx-0 px-0">
            <div className=" mt-5 py-5">
              <span className="fw-semibold h1">
                Get in touch <FaHeadset color="#09af20" size={54} />
              </span>
              <p className="mt-4 pb-5">
                Want to get in touch? We'd love to hear from you. Here's how u
                can reach us
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{
              backgroundImage: `url(${require("../Assets/customer-support.jpg")})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

        <div
          className="col-lg-4 card col-md-12 col-sm-12"
          style={{ zIndex: 1, position: "absolute", margin: "-4% 0% 0% 15%" }}
        >
          <div className="mt-4 mx-auto">
            <img src={call} style={{ height: "70px", width: "70px" }} alt="" />
          </div>

          <p className="fw-bold mt-4 mx-auto">Talk to sales</p>
          <p className="my-4 mx-4 text-center">
            {" "}
            Interested in over visa services? Just pick up the phone to chat
            with a member of our sales team
          </p>
          <p className="text-center">
            <span style={{ height: "30px", width: "30px" }}>
              <img
                src={url + configuration[0]?.imagePath}
                alt="WhatsApp Image"
                style={{ borderRadius: "50%", height: "30px", width: "30px" }}
              />
              {/* <FaWhatsapp color="green" size={25} /> */}
              {console.log(configuration[0]?.imagePath)}
            </span>
            &nbsp;{configuration[0]?.itemValue}
          </p>
          <p className="text-center">
            <span style={{ height: "30px", width: "30px" }}>
              <img
                src={url + configuration[1]?.imagePath}
                alt="Call Image"
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  scale: "0.8",
                }}
              />
              {/* <FaWhatsapp color="green" size={25} /> */}
            </span>
            &nbsp;
            {configuration[1]?.itemValue}
          </p>
        </div>

        <div
          className="col-lg-4 card col-md-12 col-sm-12 card"
          style={{ zIndex: 1, position: "absolute", margin: "-4% 0% 0% 50%" }}
        >
          <div className="mt-4 mx-auto">
            <img
              src={customer}
              style={{ height: "70px", width: "70px" }}
              alt=""
            />
          </div>

          <p className="fw-bold mt-4 mx-auto">Contact Customer-Support</p>
          <p className="my-4 mx-4 text-center">
            {" "}
            Sometimes you need a little help from your friends Or a support rep.
            Don’t worry… we’re here for you
          </p>
          <div className="text-center py-1 text-drak">
            <a
              href={`mailto:${configuration[2]?.itemValue}`}
              className="text-decoration-none"
            >
              {configuration[2]?.itemValue}
            </a>
          </div>
          <div className="text-center py-1">
            <img
              src={url + configuration[0]?.imagePath}
              style={{ height: "25px", width: "25px", borderRadius: "50%" }}
            />
            &nbsp;{configuration[0]?.itemValue}
          </div>
          <div className="text-center pb-1">
            <img
              src={url + configuration[1]?.imagePath}
              style={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                scale: "0.8",
              }}
            />
            &nbsp;
            {configuration[1]?.itemValue}
          </div>
        </div>
        <div style={{ height: "300px" }}></div>
        <div className="row container-fluid mt-5 pb-4 text-center">
          <h1>Connect with one of our global offices</h1>
        </div>
        <div className="col-lg-12 mt-5">
          <div className="row container-fluid">
            <div className="col-lg-2 col-md-1 col-sm-0"></div>
            <div className="col-lg-5 col-md-12 col-sm-12 border border-2 rounded-3  d-flex p-0 m-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d127066.72272988969!2d-0.26213192479506375!3d5.591373809168165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sP.O%20Box%20Kd%201406%20Accra%20Kanda!5e0!3m2!1sen!2sin!4v1712664202225!5m2!1sen!2sin"
                width="620"
                className="me-auto"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-lg-3 border border-2 rounded-3 ">
              <label className="mt-5 h5">Global Headquarters</label>
              <p className="ms-1">
                {configuration[3]?.itemValue}
                <br />
                {configuration[4]?.itemValue}
                <br />
                {configuration[5]?.itemValue}_{configuration[6]?.itemValue}
              </p>
              <label className="mt-5 h5">Phone/Fax</label>
              <p>
                {/* {configuration[0]?.itemValue} / {configuration[0]?.itemValue} */}
                {/* {configuration[0]?.itemValue} / {configuration[1]?.itemValue} */}
                <div className="py-1">
                  <img
                    src={url + configuration[0]?.imagePath}
                    style={{
                      height: "25px",
                      width: "25px",
                      borderRadius: "50%",
                    }}
                  />
                  &nbsp;{configuration[0]?.itemValue}
                </div>
                <div className="py-1">
                  <img
                    src={url + configuration[1]?.imagePath}
                    style={{
                      height: "25px",
                      width: "25px",
                      borderRadius: "50%",
                      scale: "0.8",
                    }}
                  />
                  &nbsp;
                  {configuration[1]?.itemValue}
                </div>
              </p>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUsPage;
