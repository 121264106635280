import { Store } from "react-notifications-component";
import Swal from "sweetalert2";
import { FnIO } from "./FnIO";

const OPTIONS = {
  NOTIFY: {
    title: "title",
    message: "Message",
    type: "info",
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated animate__fadeInRight"],
    animationOut: ["animate__animated animate__fadeOutDown"],
  },
  SWEET_ALERT: {
    titleText: "",
    icon: false, //success,error,warning,info,question
    text: "",
    allowEscapeKey: false,
    timer: false,
    // timerProgressBar: true,
    allowOutsideClick: () => {
      const popup = Swal.getPopup();
      popup.classList.remove("swal2-show");
      setTimeout(() => {
        popup.classList.add("animate__animated", "animate__headShake");
      });
      setTimeout(() => {
        popup.classList.remove("animate__animated", "animate__headShake");
      }, 500);
      return false;
    },
    // allowOutsideClick: () => !Swal.isLoading(),
    showConfirmButton: true,
    confirmButtonText: "OK",
    showDenyButton: false,
    denyButtonText: "NO",
    showCancelButton: false,
    cancelButtonText: "Cancel",
    showCloseButton: true,
    showLoaderOnConfirm: false,
    // preConfirm: () => {
    //     Swal.showValidationMessage(
    //         `Request failed: error`
    //     )
    // }
  },
};
const FnNotifyTrigger = (oNotify, AutoDismiss = true) => {
  debugger;
  let oNotifyNew = Object.assign(
    { id: FnIO.newKey() },
    OPTIONS.NOTIFY,
    oNotify
  );
  if (AutoDismiss)
    oNotifyNew.dismiss = {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
    };
  Store.addNotification(oNotifyNew);
};
const FnSweetAlertTrigger = (options) => {
  const newOpt = Object.assign({}, OPTIONS.SWEET_ALERT, options);
  if (newOpt.preConfirm) {
    options.timer = false;
  }
  return Swal.fire(newOpt);
};

export const FnNotificationUI = {
  notifySuccess: (strMessage, strTitle, AutoDismiss = true) =>
    FnNotifyTrigger(
      { message: strMessage, title: strTitle, type: "success" },
      AutoDismiss
    ),

  notifyError: (strMessage, strTitle, AutoDismiss = true) =>
    FnNotifyTrigger(
      { message: strMessage, title: strTitle, type: "danger" },
      AutoDismiss
    ),

  notifyWarning: (strMessage, strTitle, AutoDismiss = true) =>
    FnNotifyTrigger(
      { message: strMessage, title: strTitle, type: "warning" },
      AutoDismiss
    ),

  notifyInfo: (strMessage, strTitle, AutoDismiss = true) =>
    FnNotifyTrigger(
      { message: strMessage, title: strTitle, type: "info" },
      AutoDismiss
    ),

  notify: (strMessage, strTitle, AutoDismiss = true) =>
    FnNotifyTrigger(
      { message: strMessage, title: strTitle, type: "default" },
      AutoDismiss
    ),

  dialogSuccess: (Title, Message) =>
    FnSweetAlertTrigger({ titleText: Title, text: Message, icon: "success" }),

  dialogError: (Title, Message) =>
    FnSweetAlertTrigger({ titleText: Title, text: Message, icon: "error" }),

  dialogConfirmation: (Title, Message, yesLable = "Yes", noLabel = "No") =>
    FnSweetAlertTrigger({
      titleText: Title,
      text: Message,
      icon: "info",
      showConfirmButton: true,
      confirmButtonText: yesLable,
      showDenyButton: true,
      denyButtonText: noLabel,
      AutoDismiss: false,
    }),
};
