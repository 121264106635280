import Servicecard from "./Servicecard";
import axios from "axios";
import { useEffect, useState } from "react";
import { TbSquareRoundedArrowRight } from "react-icons/tb";
import ViewMore from "./ServiceList";
import { Link } from "react-router-dom";

import { WebConfig } from "../WebConfig";
import { GlobalSharedReduxFn } from "../Context";
import { useDispatch } from "react-redux";
import url from "../env";
import { FnNotificationUI } from "../Utilities";
import { useLoaderService } from "../Utilities/LoaderService";
const Visaservices = () => {
  const dispatch = useDispatch();
  // const url = "https://localhost:44397/";
  // const url = "https://evisa-dev-api.cloud7info.com/";
  const [servicesList, setServicesList] = useState([]);
  const { startLoader, stopLoader } = useLoaderService();
  // const getImageUrl = (id) => {
  //   switch (id) {
  //     case 75:
  //       return img1;
  //     case 76:
  //       return img2;
  //     case 77:
  //       return img3;
  //     case 78:
  //       return img4;
  //     case 79:
  //       return img5;
  //     default:
  //       return img1;
  //   }
  // };

  useEffect(() => {
    getServicesList();
  }, []);

  const getServicesList = async () => {
    try {
      startLoader();
      const response = await axios.get(url + "api/ourservices");
      const data = response.data;
      console.log("getservices List", data);
      setServicesList(data.filter((x) => x.status === 0));
    } catch (error) {
      console.error("Error:", error.message);
      FnNotificationUI.notifyError([], "Network Error");
    } finally {
      stopLoader();
    }
  };
  /*
  const getServicesList = () => {
    // debugger;
    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
    try {
      axios.get(url + "api/ourservices").then((response) => {
        console.log(response);

        if (response) {
          dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
        }
        const data = response.data;
        console.log("getservices List", data);
        setServicesList(data.filter((x) => x.status === 0));
        
          Visa on Arrival : 
          This visa is issued to individuals seeking entry into Ghana from countries where no Ghana consulate is available. It is also granted to those who are making emergency journeys to Ghana.

          Work Permit :
          A work permit is issued to individuals who has been employed by a company in Ghana. It is issued in the form of a letter titled as 'PERMIT TO EMPLOY FOREIGN NATIONAL'.

          Residence Permit :
          A Residence Permit allows foreign to reside in Ghana for extended periods, typically for employment or family reunification

          Dependent Permit :
          A Dependent Permit enables family members of individuals who is holding a valid visa or residence permit to legally join and reside in the country.

          Rotators Permit : 
          Rotators permit provides temporary work authorization for individuals who are assigned to specific projects abroad, enabling legal residence and employment during their assignment.

          Extension of Visa : 
          This process allows individuals to extend the validity of an existing visa, enabling them to legally stay in a country beyond the initial expiration date of their visa.

          Re-Entry Visa : 
          This permit allows individuals to leave and re-enter a country during the validity of their visa, ensuring seamless travel without risking visa expiration.

          Transit Visa :
          Transit Visa allows travelers to pass through a country enroute to their final destination, typically valid for a short duration.
        
      });
    } catch (error) {
      dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
      console.error("Error:", error.message);
    }
  };*/

  return (
    <>
      <section className="col-lg-12 col-md-12 col-sm-12 bg-white">
        <div className="row container-fluid  ">
          <div
            className="col-lg-12 col-md-12 com-sm-12"
            style={{
              backgroundImage: `url(${require("../Assets/passport-bg.png")})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "88%",
              paddingRight: "20px",
              backgroundPositionY: "12%",
            }}
          >
            <div className="fs-1 ms-4 py-3 d-flex justify-content-left align-items-center">
              {/* Over */}
              Our
              <span className="text-secondary mx-2">Visa</span> Services
            </div>

            <div className="row my-2 ms-lg-3 ms-sm-3 px-0">
              {servicesList.slice(0, 2).map((service, index) => (
                <Servicecard
                  key={index}
                  service={service}
                  title={service?.displayName}
                  details={service?.shortDescription}
                  // imgUrl={getImageUrl(service?.id)}
                  imgUrl={`${url}${service?.imagePath}`}
                />
              ))}
            </div>
            <div className="row my-2 ms-lg-3 ms-sm-3">
              <div className="col-lg-1 col-md-12 col-sm-12 m-0 p-0"></div>
              {servicesList.slice(2, 5).map((service, index) => (
                // <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                <Servicecard
                  key={index}
                  service={service}
                  title={service?.displayName}
                  details={service?.shortDescription}
                  // imgUrl={getImageUrl(service?.id)}
                  imgUrl={`${url}${service?.imagePath}`}
                />
                // </div>
              ))}
              <div className="col-lg-1 col-md-6 col-sm-6 row my-2 text-center m-0 pe-4">
                <div className="card me-3 p-0 border">
                  <label className="col mt-5 pt-5 ">
                    <h4>View more</h4>
                  </label>
                  <Link to="/allServices">
                    <button className="col border-0">
                      <TbSquareRoundedArrowRight
                        className="mb-5"
                        color="#09af20"
                        size={54}
                      />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Visaservices;
