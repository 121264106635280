import Header from "../Components/Header";
import Slider from "../Components/Slider";
import slide1 from '../Assets/banner-1.png';
import slide2 from '../Assets/banner-2.png';
import Visaservices from "../Components/Visaservices";
import Steps from "../Components/Steps";
import Footer from "../Components/Footer";
import OurClients from "./OurClients";


const HomePage = () => {
    const slides = [{ image: slide1, alt: '1' }, { image: slide2, alt: '2' }]
    return <>
        <div className="container-fluid px-0 mx-0">
            {/* <Header /> */}
            <Slider />
            <Visaservices />
            <OurClients/>
            <Steps />
            {/* <Footer /> */}
        </div>
    </>
}
export default HomePage;