import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card, Button, Table } from "react-bootstrap";
import { ErrorControl, ReactModal, TextControl } from "../../Components";
import Swal from "sweetalert2";
import axios from "axios";
import { FnHttpService, FnNotificationUI } from "../../Utilities";
import url from "../../env";
import showPopUp from "../../Utilities/ShowPopUp";
import { useLoaderService } from "../../Utilities/LoaderService";

const ClientConfiguration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [isModalOpen, setModalState] = useState(false);
  const [client, setClient] = useState([]);
  const { startLoader, stopLoader } = useLoaderService();
  // const url = "https://evisa-dev-api.cloud7info.com/";
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    getClientDetails();
  }, []);

  async function getClientDetails() {
    startLoader();
    try {
      const response = await axios.get(`${url}api/Client`);
      setClient(response.data.data);
    } catch (error) {
      console.log(error);
      FnNotificationUI.notifyError([], "Network Error");
    } finally {
      stopLoader();
    }
  }

  async function submitClientConfiguration(data) {
    if (!imageUrl && data.imageFile) {
      data.imageFile = data.imageFile[0];
    }
    const result = await showPopUp({
      title: `Are you sure to ${data.id ? "Update" : "Create"}?`,
      text: "Don't worry ! You can change this later",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1e7e34",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${data.id ? "Update" : "Create"}!`,
    });
    if (result) {
      startLoader();
      try {
        const formData = new FormData();
        FnHttpService.toFormData(formData, data);
        if (data.id) {
          const response = await axios.put(
            `${url}api/Client/${data.id}`,
            formData
          );
          if (response.data.isSuccess) {
            console.log("update" + response.data);
            FnNotificationUI.notifySuccess([], "Updated Successfully");
          }
        } else {
          const response = await axios.post(`${url}api/Client`, formData);
          if (response.data.isSuccess) {
            console.log("created" + response.data);
            FnNotificationUI.notifySuccess([], "Created Successfully");
          }
        }
        setModalState(false);
        getClientDetails();
        reset({});
        setImageUrl("");
        showPopUp({
          title: "Form submitted successfully!",
          icon: "success",
        });
      } catch (error) {
        console.log("error", error);
        FnNotificationUI.notifyError(
          [],
          `Failed to ${data.id ? "Update" : "Create"}`
        );
      } finally {
        stopLoader();
      }
    }
  }

  function openFormEditor(item) {
    if (item?.imagePath) {
      setImageUrl(url + item.imagePath);
    }
    reset(item || {});
    setModalState(true);
  }

  function updateClientConfiguration(clientData) {
    openFormEditor(clientData);
  }

  function onModalClose() {
    reset({});
    setImageUrl("");
    setModalState(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setImageUrl(fileURL);
      setValue("imageFile", file);
    }
  };
  async function deleteClient(id) {
    try {
      const askUser = await Swal.fire({
        title: "Are you sure to delete?",
        text: "You won't be able to change this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#1e7e34",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      });
      if (askUser.isConfirmed) {
        startLoader();
        const response = await axios.delete(`${url}api/Client/${id}`);
        if (response.data.isSuccess) {
          console.log("Delete", response.data);
          FnNotificationUI.notifySuccess([], "Deleted Successfully");
          getClientDetails();
          setModalState(false);
          reset({});
          setImageUrl("");
        }
      }
    } catch (error) {
      FnNotificationUI.notifyError([], "Failed to Delete");
      console.error("Error:", error.message);
    } finally {
      stopLoader();
    }
  }
  return (
    <>
      {console.log(imageUrl)}
      <div className="d-flex">
        {isModalOpen && (
          <ReactModal
            size={"md"}
            onModalClose={() => {
              onModalClose();
            }}
          >
            <form onSubmit={handleSubmit(submitClientConfiguration)}>
              <Card>
                <Card.Header>
                  <h3>{imageUrl ? "Update" : "Add"} Client</h3>
                </Card.Header>
                <Card.Body>
                  <div className="px-4 border-rounded border-dark">
                    <TextControl
                      name="itemKey"
                      formReg={register("itemKey", {
                        required: "Required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is only 100 char.",
                        },
                      })}
                      lblText="Client Unique Key"
                      errorObj={errors}
                    ></TextControl>

                    <TextControl
                      name="itemKeyValue"
                      formReg={register("itemKeyValue", {
                        required: "Required",
                        maxLength: {
                          value: 40,
                          message: "Max Length is only 40 char.",
                        },
                      })}
                      lblText="Client Name"
                      errorObj={errors}
                    ></TextControl>

                    <TextControl
                      name="itemValue"
                      formReg={register("itemValue", {
                        required: "Required",
                        maxLength: {
                          value: 200,
                          message: "Max Length is only 200 char.",
                        },
                      })}
                      lblText="About Client"
                      errorObj={errors}
                    ></TextControl>

                    <ErrorControl name={""} errorObj={errors}></ErrorControl>
                  </div>
                  <div className="row px-4 border-rounded border-dark">
                    {/* {console.log(imageUrl, 'imag')} */}
                    {/* {imageUrl && (
                      <div className="row">
                        <div className="col-4">
                          <img
                            src={imageUrl}
                            style={{ height: "150px", width: "200px" }}
                          />
                        </div>
                        <div className="col-6 d-flex justify-content-left align-items-center">
                          <div className="input-group mb-3">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupFile01"
                            >
                              Change Image
                            </label>
                            <input
                              className="form-control-file py-2 d-none"
                              id="inputGroupFile01"
                              type="file"
                              {...register("imageFile", {
                                validate: {
                                  maxSize: (file) => {
                                    if (!file[0]) return true;
                                    return (
                                      file[0]?.size <= 5 * 1024 * 1024 ||
                                      "File size should be less than 5MB"
                                    );
                                  },
                                },
                              })}
                              onChange={handleImageChange}
                            />
                          </div>
                          {/* <button type="button" className="btn btn-dark">Change Image
                                                    </button> 
                        </div>
                      </div>
                    )} */}
                    {imageUrl ? (
                      <div className="row mx-auto">
                        <div className="col-4">
                          <img
                            src={imageUrl}
                            style={{ height: "150px", width: "200px" }}
                            alt="uploaded-Data"
                          />
                        </div>
                        <div className="col-7 d-flex align-items-center">
                          <div className="input-group mb-3 justify-content-end">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupFile01"
                            >
                              Change Image
                            </label>
                            <input
                              className="form-control-file py-2 d-none"
                              id="inputGroupFile01"
                              type="file"
                              {...register("imageFile", {
                                validate: {
                                  maxSize: (file) => {
                                    if (!file[0]) return true;
                                    return (
                                      file[0]?.size <= 5 * 1024 * 1024 ||
                                      "File size should be less than 5MB"
                                    );
                                  },
                                },
                              })}
                              onChange={handleImageChange}
                            />
                          </div>
                          {/* <button type="button" className="btn btn-dark">Change Image
                                                    </button> */}
                        </div>
                      </div>
                    ) : (
                      <input
                        className="form-control-file py-2"
                        type="file"
                        {...register("imageFile", {
                          validate: {
                            maxSize: (file) => {
                              if (!file[0]) return true;
                              return (
                                file[0]?.size <= 5 * 1024 * 1024 ||
                                "File size should be less than 5MB"
                              );
                            },
                          },
                        })}
                      />
                    )}
                    {/* <Controller
                                                name="image"
                                                control={control}
                                                render={({ field }) => (
                                                    <ImageCropper2 setValue={setValue} initialImageUrl={img1} />
                                                )}
                                            /> */}
                    {/* <ImageCropper2 setValue={setValue} initialImageUrl={img1} /> */}
                  </div>
                </Card.Body>

                <div className="card-footer d-flex">
                  <Button type="submit" className="ms-auto bg-dark border-0">
                    Save
                  </Button>
                </div>
              </Card>
            </form>
          </ReactModal>
        )}
        {/* <button onClick={async ()=>{return(<Toast icon="success" title="success" iconcolor="green"></Toast>)}}>click</button> */}
        <Button
          type="button"
          className="bg-dark p-2 my-1 mb-2 ms-auto text-white rounded border-0"
          onClick={() => {
            openFormEditor({});
          }}
        >
          Add Client
        </Button>
      </div>
      <div className="col-12 mx-2 border-top" style={{ minHeight: "600px" }}>
        <Table striped bordered hover className="text-center">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Image</th>
              <th>Client ID</th>
              <th>Client Name</th>
              <th>About Client</th>
              <th colSpan="2">Action</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {client.map((x, index) => {
              return (
                <tr key={index} style={{ maxHeight: "70px" }}>
                  <td className="mt-2">{index + 1}</td>
                  <td>
                    {x.imagePath ? (
                      <img
                        alt="Uploaded Data"
                        src={url + x.imagePath}
                        // src={getImageUrl(x.id)}
                        style={{ height: "70px", width: "90px" }}
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  <td className="mt-2">{x.itemKey}</td>
                  <td className="mt-2">{x.itemKeyValue}</td>
                  <td className="mt-2">{x.itemValue}</td>
                  <td>
                    <Button
                      type="button"
                      className="mt-2"
                      variant="outline-danger"
                      onClick={() => deleteClient(x.id)}
                    >
                      Delete
                    </Button>
                  </td>
                  <td>
                    <Button
                      type="button"
                      variant="outline-warning"
                      className="mt-2"
                      onClick={() => updateClientConfiguration(x)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ClientConfiguration;
