import { FnParsing } from "../Utilities/FnParsing";

const DEFAULT_VALUE = {
    authToken: undefined,
    user: null,

}
const ACTION_TYPE = {
    AUTH_TOKEN_SET: "AUTH_TOKEN_SET",
    SET_USER: "SET_USER"
}
// const initialState = {
//     user: null,
// };

export const GlobalCurrentUserReduxFn = {
    authTokenSet: (tokenData) => {
        return {
            type: ACTION_TYPE.AUTH_TOKEN_SET,
            payload: tokenData
        }
    },
    setUser: (userDetails) => ({
        type: ACTION_TYPE.SET_USER,
        payload: userDetails,
    })

}
// export const setUser = (userDetails) => ({
//     type: ACTION_TYPE.SET_USER,
//     payload: userDetails,
// });

export const GlobalCurrentUserRedux = (state = DEFAULT_VALUE, action) => {

    let oState = undefined;
    switch (action.type) {
        case ACTION_TYPE.AUTH_TOKEN_SET:
            oState = {
                ...state,
                authToken: action.payload
            }
            break;

        case ACTION_TYPE.SET_USER:
            oState = {
                ...state,
                user: action.payload,
            }
            break;
        default: break;

    }
    return oState || state;
}

// export const GlobalCurrentUserDetailsRedux = (state = initialState, action) => {
//     let oState = undefined;
//     switch (action.type) {


//     }
//     return oState || state;
// };