import { useSelector } from "react-redux";
import { WebConfig } from "../WebConfig";
import {
  ButtonPrimary,
  ReactModal,
  TextControl,
  ButtonDark,
} from "../Components";
// import { useState } from "react";
import pic1 from "../Assets/visa-for-indians.webp";
// import { WebConfig } from "../WebConfig";
import axios from "axios";
import { useState } from "react";
// import { GlobalSharedReduxFn } from "../Context";
import { useDispatch } from "react-redux";
import { FnHttpService } from "../Utilities";
import { Controller, useForm } from "react-hook-form";
import url from "../env";
import { useLoaderService } from "../Utilities/LoaderService";

const UserProfile = () => {
  const dispatch = useDispatch();
  //  const url = "https://evisa-dev-api.cloud7info.com/";
  // const url = "https://localhost:44397/";
  const userToken = useSelector(
    (state) => state[WebConfig.contextKeys.currentUserRedux].authToken
  );
  const userProfile = useSelector(
    (row) => row[WebConfig.contextKeys.currentUserRedux].user
  );
  const [isModalOpen, setModalState] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const { startLoader, stopLoader } = useLoaderService();
  /*
  const changePassword = (data) => {
    setValue("email", userProfile.email);
    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
    let formData = new FormData();
    FnHttpService.toFormData(formData, data);
    try {
      axios
        .put(
          url + "api/usermanager/changePassword/" + userProfile.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.log("update", response);
            setModalState(false);
          }
        });
    } catch (error) {
      dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
      console.error("Error:", error.message);
    }
  };
*/
  const changePassword = async (data) => {
    try {
      setValue("email", userProfile.email);
      startLoader();
      let formData = new FormData();
      FnHttpService.toFormData(formData, data);
      const response = await axios.put(
        url + "api/usermanager/changePassword/" + userProfile.id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log("Password Updated", response.data);
      setModalState(false);
    } catch (error) {
      console.log("Error :", error.message);
    } finally {
      stopLoader();
    }
  };
  return (
    <>
      {console.log(userProfile, "userProfie")}
      <div className="user-profile row container-fluid px-0 mx-0 ">
        <div className="col-lg-4 col-md-4 col-sm-0">
          <img
            src={pic1}
            className="image-fluid"
            style={{ maxWidth: "100%" }}
          />
        </div>
        <div className="col-lg-8 py-5 bg-white">
          <div className="d-flex py-3">
            <ButtonPrimary
              onClick={() => {
                setModalState(true);
              }}
              type="button"
              className="text-white ms-auto me-3 px-5"
            >
              Change Password
            </ButtonPrimary>
          </div>
          {/* <ButtonDark type="submit" className="text-white mx-auto px-5">Save</ButtonDark> */}
          <div className="row">
            <div className="col-lg-6 px-5">
              {/* <p><strong>Name:</strong></p> */}
              <p>
                <strong>Email:</strong>
              </p>
              <p>
                <strong>Contact Number:</strong>{" "}
              </p>
              <p>
                <strong>Country Code:</strong>
              </p>
              {/* <p><strong>Role:</strong></p> */}
            </div>
            <div className="col-lg-6 px-5">
              {/* <p> {userProfile.name}</p> */}
              <p> {userProfile?.email}</p>
              <p> {userProfile?.contactNumber}</p>
              <p>{userProfile?.countryCode}</p>
              {/* <p>{userProfile?.roleName || 'Not specified'}</p> */}
            </div>
          </div>
        </div>
        {isModalOpen == true && (
          <ReactModal
            size={"md"}
            onModalClose={() => {
              setModalState(false);
            }}
          >
            <div className="p-3 border-rounded card">
              <form onSubmit={handleSubmit(changePassword)}>
                <div className="card-header mb-2">
                  <h3>Change password</h3>
                </div>
                <div className="mx-2 mb-2">
                  <TextControl
                    lblText={"E-mail"}
                    formReg={register("email")}
                    errorObj={errors}
                    name={"email"}
                    disable={true}
                    value={userProfile.email}
                    placeholder="Enter Your Email"
                  ></TextControl>
                </div>
                <div className="mx-2 mb-2">
                  <TextControl
                    value={"adf"}
                    lblText={"New Password"}
                    type="password"
                    formReg={register("newpassword", {
                      required: "Password Required",
                      maxLength: {
                        value: 20,
                        message: "Max Length is only 20 char.",
                      },
                      minLength: {
                        value: 3,
                        message: "Atleast minimum 3 char. required",
                      },
                      // pattern: {
                      //     value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                      //     message: "Password should contain one Capital Letter, one Small Letter and One Special character"
                      // }
                    })}
                    errorObj={errors}
                    name={"newpassword"}
                  ></TextControl>
                </div>
                <div className="mx-2 mb-2">
                  <TextControl
                    value={""}
                    lblText={"Confirm Password"}
                    type="password"
                    formReg={register("confirmPassword", {
                      required: "Confirm Password Required",
                      validate: {
                        matchesPassword: (value) => {
                          const password = getValues("newpassword");
                          return value === password || "Passwords do not match";
                        },
                      },
                    })}
                    errorObj={errors}
                    name={"confirmPassword"}
                  />
                </div>

                <div className="d-flex">
                  <ButtonDark type="submit" className="text-white mx-auto px-5">
                    Chnage Password
                  </ButtonDark>
                </div>
              </form>
            </div>
          </ReactModal>
        )}
      </div>
    </>
  );
};
export default UserProfile;
