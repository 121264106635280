import axios from 'axios';
import { useSelector } from 'react-redux';
import { WebConfig } from '../WebConfig';
export class WebClientAbs {
    httpClient;
    reponseVersion = 1;
    tInfo = useSelector(row => row[WebConfig.contextKeys.globalSharedRedux].tenantInfo);

    constructor(baseUrl, apiController, reponseTypeVersion,  useClientId=false) {

        this.httpClient = axios.create(
            Object.assign({}, {
                baseURL: `${baseUrl}${apiController}${useClientId ? ("/" + this.tInfo.objId) : ""}`
            })
        );

        this.reponseVersion = reponseTypeVersion;
        this.responseInterceptorFn()
    }

    responseInterceptorFn() {
        this.httpClient.interceptors.request.use(function (config) {

            // oDispatch(globalActions.ApiRequestCountChange(true))
            return config;
        });

        this.httpClient.interceptors.response.use(
            ({ config, status, statusText, headers, data }) => {
                if (this.reponseVersion === 1) {
                    //oDispatch(globalActions.ApiRequestCountChange(false))
                    if (!data.isSuccess) {
                        return Promise.reject(data);
                    }
                }

                return data.data || data;
            },
            (error) => {
                let errorMessage = error;
                const resp = error.response;
                console.log("generic error", error, resp)

                if (!resp) {
                    alert("No Resp")
                }
                return Promise.reject(error);
            }
        );
        // return axiosInstance;
    }
}


