import { createTransform } from 'redux-persist';
import { GlobalSharedRedux, GlobalCurrentUserRedux } from "./";
const { combineReducers } = require("redux");
export const rootReduxReducers = combineReducers({
    GlobalSharedRedux,
    GlobalCurrentUserRedux
    // EComGlobalRedux
})



// export const SetTransform = createTransform(
//   // transform state on its way to being serialized and persisted.
//   (inboundState, key) => {
//     debugger
//     // convert mySet to an Array.
//     return { ...inboundState, mySet: [...inboundState.mySet] };
//   },
//   // transform state being rehydrated
//   (outboundState, key) => {
//     console.log(outbount)
//     // convert mySet back to a Set.
//     return { ...outboundState, mySet: new Set(outboundState.mySet) };
//   },
//   // define which reducers this transform gets called for.
//   { whitelist: ['GlobalCurrentUserRedux'] }
// );

